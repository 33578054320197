import { createRouter, createWebHashHistory } from 'vue-router';
import ChatView from '../views/ChatView.vue';
import CommentsView from '../views/CommentsView.vue';
import ResultView from '../views/ResultView.vue';
import LessonView from '../views/LessonView.vue';
import LoginView from '../views/LoginView.vue';
import RobotView from '../views/RobotView.vue';
import SessionsView from '../views/SessionsView.vue';
import storageHelper from 'storage-helper';

const routes = [
  {
    path: '/result/:id',
    name: 'home',
    component: ResultView
  },
  {
    path: '/lesson/:id',
    name: 'lesson',
    component: LessonView
  },
  {
    path: '/chat/:id',
    name: 'chat',
    component: ChatView
  },
  {
    path: '/comments/:email',
    name: 'comments',
    component: CommentsView,
    props: (route: string) => {
      return {
        emailAddress: route.params.email,
        teacherViewing: route.query.teacher
      };
    }
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: SessionsView
  },
  {
    path: '/robot/:id',
    name: 'robot',
    component: RobotView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    props: (route: string) => ({
        toPath: route.query.q,
        adminPassword: route.query.a
    })
    
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    if ((to.name !== 'login' && to.name !== 'chat' && to.name !=='comments') && !storageHelper.getItem('user-password')) {
      console.log('NO PASSWORD FOUND')
      next(`/login?q=${String(to.path)}`);
    }
    else next()
})

export default router
